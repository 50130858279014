import React, { useContext, useEffect, useState } from 'react';
import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faLongArrowAltLeft,
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { useFetch, FETCH_STATES } from '../../hooks/useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import { Link, navigate } from 'gatsby';
import { useMutation, useQuery } from 'react-query';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import NewStaffMember, { NewStaffMemberForm } from './NewStaffMember';
import { IGrade, IOrganization, IPosition } from './NewApiTypes';
import { QueryReturnState } from './Enum';
import moment from 'moment';
import { UserOrganizationInvite } from './NewApiTypes.generated';
import {
  createUserOrganizationInvitationTCAL,
  deleteUserFromOrganizationTCAL,
  getGradesTCAL,
  getOrganizationTCAL,
  getPositionsTCAL,
  updateContentAccessTCAL,
  updateOrganizationMembershipTCAL
} from '../../tcal-api';

interface UserManagementDropdownProps {
  type: 'ADMIN' | 'TOOLKIT';
  isAdmin?: boolean;
  hasToolkit?: boolean;
  onMakeAdmin?: any;
  onRemoveAdmin?: any;
  onManageToolkitAccess?: any;
}

const UserManagementDropdown = (props: UserManagementDropdownProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left bg-white">
      <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-10">
        <FontAwesomeIcon
          icon={faChevronDown}
          className="text-gray-400 hover:text-gray-600"
        />
      </Menu.Button>
      <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-3 z-10">
        {props.type === 'ADMIN' ? (
          <Menu.Item>
            {({ active }) => (
              <>
                {props.isAdmin ? (
                  <button
                    className="sans-serif hover:underline text-red-600 w-full text-left"
                    onClick={props.onRemoveAdmin}
                  >
                    Remove Admin
                  </button>
                ) : (
                  <button
                    className="sans-serif hover:underline text-gray-800 w-full text-left"
                    onClick={props.onMakeAdmin}
                  >
                    Make Admin
                  </button>
                )}
              </>
            )}
          </Menu.Item>
        ) : props.type === 'TOOLKIT' ? (
          <Menu.Item>
            {({ active }) => (
              <>
                {props.hasToolkit ? (
                  <button
                    className="sans-serif hover:underline text-red-600"
                    onClick={props.onManageToolkitAccess}
                  >
                    Remove Toolkit Access
                  </button>
                ) : (
                  <button
                    className="sans-serif hover:underline"
                    onClick={props.onManageToolkitAccess}
                  >
                    Allow Toolkit Access
                  </button>
                )}
              </>
            )}
          </Menu.Item>
        ) : null}
      </Menu.Items>
    </Menu>
  );
};

interface SchoolRosterProps {
  path: string;
  organizationId: string;
}
const SchoolRoster = (props: SchoolRosterProps) => {
  const auth = useContext(AuthContext);
  const { userInfo } = auth;

  const [users, setUsers] = useState<any[]>([]);
  const [currentUserIsOrgOwner, setCurrentUserIsOrgOwner] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddStaffMember, setShowAddStaffMember] = useState(false);
  const {
    data: organizationData,
    isFetching,
    refetch: refetchOrganizationData
  } = useQuery<{
    state: QueryReturnState;
    organization?: IOrganization;
  }>('organization', () => getOrganizationTCAL(props.organizationId));

  const { data: gradesData } = useQuery<{
    state: QueryReturnState;
    grades?: IGrade[];
  }>('grades', getGradesTCAL);
  const { data: positionsData } = useQuery<{
    state: QueryReturnState;
    positions?: IPosition[];
  }>('positions', getPositionsTCAL);

  useEffect(() => {
    setUsers([
      ...(organizationData?.organization?.memberships?.map(m => ({
        userId: m.user?.id,
        name: `${m.user?.firstName} ${m.user?.lastName}`,
        email: m.user?.email,
        toolkitAccess: m.user?.contentAccess?.toolKitAccess,
        schoolLeaderAccess: m.user?.contentAccess?.schoolLeaderAccess,
        role: m.role,
        membershipId: m.id
      })) || []),
      ...(organizationData?.organization?.userOrganizationInvites?.map(
        (i: UserOrganizationInvite) => ({
          userId: i.user?.id,
          name: `${i.user?.firstName} ${i.user?.lastName}`,
          email: i.user?.email,
          toolkitAccess: i.user?.contentAccess?.toolKitAccess,
          schoolLeaderAccess: i.user?.contentAccess?.schoolLeaderAccess,
          role: i.role,
          membershipId: i.id,
          isPending: true
        })
      ) || [])
    ]);

    if (organizationData && !isFetching) {
      const member = organizationData?.organization?.memberships?.find(
        m => m?.user?.authProviderId === auth.authState?.authProviderId
      );
      const isAdminOrOwner =
        member?.role === 'ADMIN' || member?.role === 'OWNER';
      setCurrentUserIsOrgOwner(member?.role === 'OWNER');
      if (!isAdminOrOwner) {
        navigate('/members/profile');
      }
    }
  }, [organizationData, auth]);

  const makeAdminMutation = useMutation(
    async (data: any) => {
      const { membershipId } = data;

      if (
        confirm(
          'Designating this role to a staff member at this school gives them access to application, staff, and billing information. Do you wish to proceed?'
        )
      ) {
        await updateOrganizationMembershipTCAL(membershipId, { role: 'ADMIN' });

        return membershipId;
      } else {
        throw new Error('Action canceled');
      }
    },
    {
      onSuccess: membershipId => {
        toast.success('The user has been made an admin');
        setUsers(
          users.map(u => {
            if (u.membershipId === membershipId) {
              return {
                ...u,
                role: 'ADMIN'
              };
            }
            return u;
          })
        );
      }
    }
  );

  const removeAdminMutation = useMutation(
    async (data: any) => {
      const { membershipId } = data;
      if (
        confirm(
          'Removing this role from a staff member at this school revokes their access to some application, staff, and billing information. Do you wish to proceed?'
        )
      ) {
        await updateOrganizationMembershipTCAL(membershipId, {
          role: 'MEMBER'
        });
        return membershipId;
      } else {
        throw new Error('Action canceled');
      }
    },
    {
      onSuccess: membershipId => {
        toast.success("The user's admin rights have been removed");
        setUsers(
          users.map(u => {
            if (u.membershipId === membershipId) {
              return {
                ...u,
                role: 'MEMBER'
              };
            }
            return u;
          })
        );
      }
    }
  );

  const manageTreasureChestAccessMutation = useMutation(
    async (data: any) => {
      const { userId, allowAccess } = data;
      const user = users.find(u => u.userId === userId);
      const confirmMessage = !!allowAccess
        ? 'Are you sure you want to grant this member access to Toolkit?'
        : "Are you sure you want to remove this member's access to Toolkit?";

      if (confirm(confirmMessage)) {
        await updateContentAccessTCAL({
          userId,
          toolKitAccess: allowAccess,
          schoolLeaderAccess: user.schoolLeaderAccess
        });
        return data;
      }
    },
    {
      onSuccess: data => {
        const updatedUsers = users.map(u => {
          if (u.userId === data.userId) {
            return {
              ...u,
              toolkitAccess: data.allowAccess
            };
          }
          return u;
        });
        setUsers(updatedUsers);
        if (!!data.allowAccess) {
          toast.success('The user has been granted access to Toolkit');
        } else {
          toast.success("The user's access to Toolkit has been removed");
        }
      }
    }
  );

  const deleteUserFromOrganizationFetch = useFetch(
    deleteUserFromOrganizationTCAL
  );
  const submitDeleteStaffMember = async (userId: string) => {
    const { state, error } = await deleteUserFromOrganizationFetch.apply({
      organizationId: props.organizationId,
      userId
    });

    if (state === 'SUCCESS') {
      await refetchOrganizationData();
      toast.success('The staff member has been deleted.');
    } else {
      toast.error(error || 'Something went wrong');
    }
  };

  const createUserOrganizationInvitationFetch = useFetch(
    createUserOrganizationInvitationTCAL
  );
  const submitAddNewStaffMember = async (values: NewStaffMemberForm) => {
    const {
      data: createdUser,
      state,
      error
    } = await createUserOrganizationInvitationFetch.apply({
      ...values,
      dateOfBirth: values.dateOfBirth
        ? moment(values.dateOfBirth, 'MM/DD/YYYY').toISOString()
        : null,
      organizationId: props.organizationId,
      ssnLastFour: Number(values.ssnLastFour)
    });

    if (state === 'SUCCESS') {
      await refetchOrganizationData();

      toast.success(
        `Success! ${createdUser?.user?.firstName} ${createdUser?.user?.lastName} (${createdUser?.user?.email}) has now been invited to this school.`
      );

      setShowAddStaffMember(false);
    } else {
      toast.error(error || createdUser?.message || 'Something went wrong');
    }
  };

  // const { data: toolkitInvitees } = useQuery('toolkitInvitees', () =>
  //   getToolkitInvitees(props.organizationId)
  // );

  const baseColumns = [
    {
      Header: 'Name',
      accessor: (row: typeof users[number]) => (
        <div className="flex gap-2">
          <div>{row.name}</div>
          {row.isPending && (
            <div className="text-sm rounded-full border-gray-600 text-gray-600 bg-gray-200 px-3 py-1">
              Pending
            </div>
          )}
        </div>
      )
    },
    {
      Header: 'Email',
      accessor: 'email'
    }
  ];

  const toolkitAccessColumns = [
    {
      Header: 'Toolkit',
      accessor: 'toolkitAccess',
      Cell: (props: any) =>
        props.row.original.isPending ? (
          <></>
        ) : (
          <>
            <div className="flex">
              {props.cell.value === true ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-green-600 my-auto"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-red-600 my-auto"
                />
              )}
              <UserManagementDropdown
                type="TOOLKIT"
                hasToolkit={props.cell.value}
                onManageToolkitAccess={() =>
                  manageTreasureChestAccessMutation.mutate({
                    userId: props.row.original.userId,
                    allowAccess: !props.cell.value
                  })
                }
              />
            </div>
          </>
        )
    }
  ];

  const adminAccessColumns = [
    {
      Header: 'Admin',
      accessor: 'role',
      Cell: (props: any) =>
        props.row.original.isPending ? (
          <></>
        ) : (
          <>
            {props.cell.value !== 'OWNER' && (
              <div className="flex">
                {props.cell.value === 'ADMIN' ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-green-600 my-auto"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-red-600 my-auto"
                  />
                )}
                <UserManagementDropdown
                  type="ADMIN"
                  isAdmin={props.cell.value === 'ADMIN'}
                  onMakeAdmin={() => {
                    const { membershipId } = props.row.original;
                    if (!membershipId) {
                      return alert(
                        'The user could not be made admin. Please contact support.'
                      );
                    }

                    makeAdminMutation.mutate({
                      membershipId
                    });
                  }}
                  onRemoveAdmin={() => {
                    const { membershipId } = props.row.original;
                    if (!membershipId) {
                      return alert(
                        'The user could not be removed. Please contact support.'
                      );
                    }

                    removeAdminMutation.mutate({
                      membershipId
                    });
                  }}
                />
              </div>
            )}
          </>
        )
    }
  ];

  const deleteMemberColumns = [
    {
      Header: 'Edit',
      accessor: '',
      Cell: (props: any) =>
        props.row.original.isPending ? (
          <></>
        ) : (
          <button
            className="text-red-500 sans-serif text-sm hover:underline"
            onClick={() => {
              if (props.row.original.userId === auth?.authState?.id) {
                return alert(
                  'You cannot delete yourself from an organization. Please contact support.'
                );
              } else if (
                confirm('Are you sure you want to delete this member?')
              ) {
                submitDeleteStaffMember(props.row.original.userId);
              }
            }}
          >
            Delete Member
          </button>
        )
    }
  ];

  const adminColumns = [
    ...baseColumns,
    ...adminAccessColumns,
    ...toolkitAccessColumns,
    ...deleteMemberColumns
  ];

  const schoolAdminColumns = [
    ...baseColumns,
    ...toolkitAccessColumns,
    ...deleteMemberColumns
  ];

  let columns: any[] = [];
  console.log(auth);

  if (currentUserIsOrgOwner) {
    columns = React.useMemo(() => adminColumns, [currentUserIsOrgOwner]);
  } else {
    columns = React.useMemo(() => schoolAdminColumns, [currentUserIsOrgOwner]);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: users
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <Card>
        <SEO title="Manage School Roster" />
        {isFetching && <LoadingDialog />}
        {!isFetching && organizationData?.organization?.memberships?.length && (
          <>
            <h3 className="text-primary font-bold text-2xl mb-6">
              Manage School Roster
            </h3>
            <div className="mt-2 mb-4 flex">
              <Link to={`/members/organization/${props.organizationId}`}>
                <Button
                  text="Back to Organization"
                  icon={faLongArrowAltLeft}
                  size="sm"
                />
              </Link>
              {!showAddStaffMember && (
                <div className="ml-2">
                  <Button
                    text="Add a Staff Member"
                    size="sm"
                    icon={faPlus}
                    onClick={() => setShowAddStaffMember(true)}
                  />
                </div>
              )}
            </div>
            {showAddStaffMember && (
              <NewStaffMember
                positions={positionsData?.positions || []}
                grades={gradesData?.grades || []}
                organizationId={props.organizationId}
                onCancel={() => setShowAddStaffMember(false)}
                onSubmit={userData => submitAddNewStaffMember(userData)}
                isSubmitting={
                  isFetching ||
                  createUserOrganizationInvitationFetch.state ===
                    FETCH_STATES.PROCESSING
                }
              />
            )}
            <input
              className="my-4 sm:w-96 py-3 px-4 focus:outline-none rounded-full bg-gray-100 sans-serif"
              placeholder="Search for a member"
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
                setGlobalFilter(e.target.value || undefined);
              }}
            />
            <div className="">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-100"
              >
                <thead>
                  {// Loop over the header rows
                  headerGroups.map((headerGroup: any) => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {// Loop over the headers in each row
                      headerGroup.headers.map((column: any) => (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="sans-serif px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {// Render the header
                          column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span className="ml-2">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faCaretDown} />
                              ) : (
                                <FontAwesomeIcon icon={faCaretUp} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {/* Apply the table body props */}
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200 sans-serif"
                >
                  {// Loop over the table rows
                  rows.map((row: any) => {
                    // Prepare the row for display
                    prepareRow(row);
                    return (
                      // Apply the row props
                      <tr {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map((cell: any) => {
                          // Apply the cell props
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-no-wrap sans-serif"
                            >
                              {// Render the cell contents
                              cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Card>
      {/* {toolkitInvitees &&
        !!toolkitInvitees.length &&
        (userInfo.access.admin || userInfo.access.institute_admin) && (
          <div className="mt-6">
            <Card>
              <h3 className="text-primary font-bold text-2xl mb-6">Invitees</h3>
              <ToolkitInvitees invitees={toolkitInvitees} />
            </Card>
          </div>
        )} */}
    </>
  );
};

export default SchoolRoster;
